<template>
  <div
    class="text-item-title"
  >
    <textarea
      height="0px"
      placeholder="Enter a Title"
      class="text-item-title__input"
      type="text"
      v-model="model"
      :readonly="readonly"
      @blur="submit"
      ref="input"
      @input="onInput"
      @focus="e => $emit('focus', e)"
    />
  </div>
</template>
<script>
export default {
  props: {
    readonly: {
      type: Boolean
    },
    value: {
      required: true
    }
  },
  name: 'TextItemTitle',
  data: () => ({
    model: null
  }),
  created() {
    this.model = this.value
  },
  mounted() {
    this.onInput()
    
  },
  methods: {
    submit() {
      this.$emit('blur')
      const isEqual = this.value === this.model
      const { input } = this.$refs
      
      input && input.blur()
      if (isEqual) return

      this.$emit('input', this.model)
      console.log(this.value)
    },
    onInput() {
      const { input } = this.$refs
      this.$nextTick(() => {
        input.style.cssText = 'height: 0px; padding: 0px;'

        input.style.cssText = `height: ${input.scrollHeight}px`
        console.log(input.scrollHeight)
      })
    }
  }
}
</script>
<style lang="scss">
.text-item-title {
  margin: 0 0 40px;

  &__input {
    outline: none;
    font-size: 30px;
    overflow:hidden;
    display: block;
    width: 100%;
    resize: none;
    line-height: 1.2;

    &[readonly] {
      cursor: default;
    }
  }
}
</style>